import type { IReview } from './review';
import type { IUser } from './user';
import {
  USER_REVIEW_PEER_TYPE,
  USER_REVIEW_REQUEST_TYPE,
  USER_REVIEW_SORT_OPTIONS,
  USER_REVIEW_STATUS,
  ROLES,
  REVIEW_TYPES,
  REVIEW_LAST_STATUS_METHOD,
  GOAL_TYPES,
} from '@learned/constants';
import type { ICareerPlan } from './careerPlan';
import type { IMeta } from './meta';
import type { ISkill } from './skill';
import type { ISkillCategory } from './skillCategories';
import type { IGoal } from './goal';
import { IUserReviewQuestion, IUserReviewQuestionPopulated } from './userReviewQuestions';
import type { IJobProfile } from './jobProfile';
import type { ITask } from './tasks';
import type { IFile } from './file';
import { IReviewTemplate } from './reviewTemplates';
import { IReviewRating } from './reviewRatings';

export interface IUserReviewSignature {
  role: ROLES.USER | ROLES.COACH;
  userId: IUser['id'];
  signature: string;
  comment: string;
  signatureDate: Date | string;
  isSeen?: boolean; // only for user
  isAgree?: boolean; // only for user
}

export interface IPeer {
  type: USER_REVIEW_PEER_TYPE;
  value: string;
}

export interface IUserReview {
  id: string;
  company: string;
  name: IReview['name'];
  description: IReview['description'];
  review: IReview['id'];
  createdIn: ROLES.ADMIN | ROLES.COACH | ROLES.USER;
  createdFor: IUser['id'];
  createdBy: IUser['id'];
  peers: IPeer[];
  guests: IUser['id'][];
  coaches: IUser['id'][];
  careerPlans: ICareerPlan['id'][];
  status: USER_REVIEW_STATUS;
  lastStatus: USER_REVIEW_STATUS | null;
  lastStatusChangeMethod: REVIEW_LAST_STATUS_METHOD | null;
  lastStatusChangeDate: Date | null;
  meta: IMeta;
  privacy: IReview['privacy'];
  settings: IReview['settings'];
  goalsPlanned: {
    id: IGoal['id'];
    name: IGoal['name'];
    subType: GOAL_TYPES.BUSINESS | GOAL_TYPES.PERSONAL;
  }[];
  isDeleted: boolean;
  backup: {
    skills: Record<string, ISkill>;
    skillCategories: Record<ISkillCategory['id'], ISkillCategory>;
    careerPlans: Record<ICareerPlan['id'], ICareerPlan>;
    jobProfiles: Record<IJobProfile['id'], IJobProfile>;
  } | null;
  signatures: IUserReviewSignature[];
  userReviewQuestions: IUserReviewQuestion['id'][];
  type: REVIEW_TYPES;
  dateOfConversation: Date | null;
  dateOfConversationEnd: Date | null;
  includeLinkMeeting?: boolean | null;
  attachments?: string[];
  version?: IReview['version'];
}

export type IAddUserReviews = Pick<
  IUserReview,
  'createdFor' | 'careerPlans' | 'coaches' | 'guests'
>;
export type IEditUserReviews = Pick<IUserReview, 'id' | 'careerPlans' | 'coaches' | 'guests'>;
export type IDeleteUserReviews = IUserReview['id'];

export interface IPreviousRatingResponse {
  lastRating: {
    rating: IReviewRating;
    userReview: Partial<IUserReview>;
  };
}

export interface IUserReviewPopulated
  extends Omit<IUserReview, 'createdFor' | 'userReviewQuestions' | 'attachments'> {
  createdFor: IUser['id'] | Pick<IUser, 'id' | 'avatarUrl' | 'email' | 'firstName' | 'lastName'>;
  userReviewQuestions: IUserReviewQuestion['id'][] | IUserReviewQuestionPopulated[];
  tasks?: ITask[];
  employeeTasksInOtherUserReviews?: ITask[]; // employee tasks in review cycle (but not from his own UserReview)
  attachments?: string[] | IFile[];
  calendarEvent?: any; // TODO update
  reviewTemplate?: IReviewTemplate;
  previousRatings?: Record<string, IPreviousRatingResponse>;
}

// Request types
export interface IGetUserReviewsRequest {
  filters: {
    search?: string;
    status?: USER_REVIEW_STATUS[];
    employees?: IUser['id'][];
    timeframe?: [Date, Date];
    createdIn?: ROLES[];
    review?: IUserReview['id'][];
  };
  options: {
    skip?: number;
    limit?: number;
    sortBy?: USER_REVIEW_SORT_OPTIONS;
    projection?: string[];
  };
  populate?: POPULATE_USER_REVIEW_ITEMS[];
  type?: USER_REVIEW_REQUEST_TYPE;
}

export interface IGetUserReviewsByIdRequest {
  populate?: POPULATE_USER_REVIEW_BY_ID[];
  join?: JOIN_USER_REVIEW_BY_ID[];
  joinToQuestions?: JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID[];
}

// TODO move enums and consts to constants package not types
export enum POPULATE_USER_REVIEW_BY_ID {
  QUESTIONS = 'questions',
  ATTACHMENTS = 'attachments',
}

export enum JOIN_USER_REVIEW_BY_ID {
  TASKS = 'tasks',
  EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS = 'employeeTasksInOtherUserReviews',
  CALENDAR_EVENT = 'calendarEvent',
  REVIEW_TEMPLATE = 'reviewTemplate',
  PREVIOUS_RATINGS = 'previousRatings',
}

export enum JOIN_TO_QUESTIONS_USER_REVIEW_BY_ID {
  REVIEW_RATINGS = 'reviewRatings',
  JOB_PROFILES = 'jobProfiles',
  REVIEW_THEMES = 'reviewThemes',
  SKILLS = 'skills',
}

export enum POPULATE_USER_REVIEW_ITEMS {
  CREATED_FOR = 'createdFor',
}
