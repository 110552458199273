import React, { ReactNode } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { IPreviousRatingResponse } from '@learned/types';
import { Trans } from '@lingui/macro';
import { isNull } from 'lodash';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import {
  StyledIcon,
  Text,
  Container,
  StarContainer,
  IconContainer,
} from './FocusAreaRating.design';

import { COLORS } from '~/styles';

import PreviousAnswerIndicator from '../Components/PreviousAnswerIndicator';

import type { IQuestionCustomSkillData, IQuestionSkillCategoryData } from '../../../types';
import type { ISkillQuestionForm } from '../SkillQuestion';

function getStarSize(numberOfOptions: number) {
  if (numberOfOptions <= 5) {
    return 32;
  }
  if (numberOfOptions <= 6) {
    return 28;
  }
  if (numberOfOptions <= 7) {
    return 24;
  }
  if (numberOfOptions <= 8) {
    return 21;
  }
  if (numberOfOptions <= 9) {
    return 18;
  }
  if (numberOfOptions <= 10) {
    return 16;
  }
  return 16;
}

function FocusAreaRating({
  focusAreaQuestion,
  onChange,
  index,
  form,
  hasError,
  disabled,
  useMultiLangString,
  previousRating,
}: {
  index: number;
  focusAreaQuestion:
    | IQuestionCustomSkillData['subQuestions'][0]
    | IQuestionSkillCategoryData['subQuestions'][0];
  form: UseFormReturn<ISkillQuestionForm>;
  onChange?: (data: {
    questionId: string;
    answer?: number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  hasError?: boolean;
  disabled?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  previousRating: IPreviousRatingResponse | undefined;
}) {
  const { control, watch } = form;
  const field = watch(`answers.${index}.value`);
  const options = focusAreaQuestion.question.settings.options;
  const getMultiLangString = useMultiLangString();

  const previousReviewAnswer = previousRating?.lastRating?.rating?.answer;
  const previousReviewAnswerIndex = Number(previousReviewAnswer) - 1 ?? -1;

  return (
    <Container>
      <div>
        <Text hasError={hasError && (field === undefined || field < 0)}>
          {getMultiLangString(focusAreaQuestion.question.settings.focusArea.name)}
        </Text>
        {isNull(previousReviewAnswer) && (
          <PreviousAnswerIndicator tooltipName={previousRating?.lastRating.userReview.name} />
        )}
      </div>
      <StarContainer>
        <Controller
          control={control}
          name={`answers.${index}.value`}
          render={({ field }) => (
            <>
              {options.map(({ label }, index) => {
                const isSelected =
                  field.value !== undefined && field.value !== null && index + 1 === field.value;
                const isPreviousAnswer = previousReviewAnswerIndex === index;
                return (
                  <ToolTip
                    key={index}
                    placement={TOOLTIP_PLACEMENTS.BOTTOM}
                    tooltip={
                      <ToolTipText
                        label={
                          <span>
                            {index + 1}/{options.length} - {getMultiLangString(label)}
                          </span>
                        }
                        previousRatingAnswerName={
                          isPreviousAnswer
                            ? getMultiLangString(previousRating?.lastRating.userReview.name || {})
                            : undefined
                        }
                      />
                    }
                  >
                    <IconContainer
                      starSize={getStarSize(options.length)}
                      isPreviousAnswer={isPreviousAnswer}
                    >
                      {previousReviewAnswerIndex === index && (
                        <div className="history-label">
                          <Icon
                            icon={ICONS.HISTORY}
                            size={ICON_SIZES.SMALL}
                            color={COLORS.TIPPY_BACKGROUND}
                          />
                        </div>
                      )}
                      <StyledIcon
                        isSelected={!disabled && isSelected}
                        height={getStarSize(options.length)}
                        width={getStarSize(options.length)}
                        icon={ICONS.QUESTION_STAR}
                        onClick={() => {
                          field.onChange(isSelected ? -1 : index + 1);
                          onChange?.({
                            questionId: focusAreaQuestion.question.id,
                            answer: isSelected ? -1 : index + 1,
                          });
                          if (
                            focusAreaQuestion.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
                          ) {
                            focusAreaQuestion.question.settings.duplicateQuestions?.forEach(
                              (dupe) =>
                                onChange?.({
                                  questionId: dupe.question.id,
                                  answer: isSelected ? -1 : index + 1,
                                }),
                            );
                          }
                        }}
                      />
                    </IconContainer>
                  </ToolTip>
                );
              })}
            </>
          )}
        />
      </StarContainer>
    </Container>
  );
}

export { FocusAreaRating };

const ToolTipText = ({
  label,
  previousRatingAnswerName: previousRatingAnswerName,
}: {
  label: ReactNode;
  previousRatingAnswerName?: string | undefined;
}) => {
  return (
    <div>
      <div>{label}</div>
      {previousRatingAnswerName && (
        <>
          <br />
          <div>
            <Trans>Previous review</Trans>
            <div>{previousRatingAnswerName}</div>
          </div>
        </>
      )}
    </div>
  );
};
