import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_QUESTION_TYPES_V1 } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { IconOld } from '~/components/IconOld';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IQuestionCustomSkillData,
  IQuestionCustomSkillV1Data,
  IQuestionData,
  IQuestionDefaultData,
  IQuestionGoalPlanData,
} from '~/pages/ReviewGiveFeedback/types';
import { ThemeContainer } from '~/pages/ReviewThemeSetup/components/QuestionPreviewModal/design';

import { OldSkillQuestion } from './Questions/OldSkillQuestion';
import { PlanGoalQuestion } from './Questions/PlanGoalQuestion';
import { RatingQuestion } from './Questions/RatingQuestion';
import { TextAreaQuestion } from './Questions/TextAreaQuestion';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

import type { IUserReview, IUserReviewPopulated } from '@learned/types';

interface IQuestionView {
  questionData: IQuestionData;
  languageState: ILanguageStateReturn;
  className?: string;
  ratings: AnswerReviewForm['ratings'];
  reviewTask?: IPopulatedReviewTask;
  onChange?: (data: {
    questionId: string;
    oldAnswer?: string | null | number;
    answer?: string | null | number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  onBlurTextArea?: () => void;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  goalsPlanned?: IUserReview['goalsPlanned'];
  setGoalsPlanned: Dispatch<SetStateAction<IUserReview['goalsPlanned'] | undefined>>;
  userReview?: IUserReviewPopulated;
  isPreview?: boolean;
}

export interface WithComments {
  comment?: string;
}

const Container = styled.div`
  margin-top: 66px;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: center;
`;

const AnswerView = styled.div<{ overflow?: string }>`
  width: fit-content;
  min-width: 750px;
  padding: 23px 33px;
  background: ${COLORS.WHITE};
  overflow: ${({ overflow }) => overflow || 'auto'};
  margin-bottom: 88px;
  border-radius: 6px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 2px;
`;

const Description = styled.div`
  .showMore {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    color: ${COLORS.COMPANY};
  }

  *:not(.showMore) {
    width: 750px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${COLORS.SUBTEXT};
  }
`;

const ThemeTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.SUBTEXT};
  .title {
    margin-left: 2px;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 14px;
`;

const QuestionView = ({
  languageState,
  className,
  questionData,
  ratings,
  onChange,
  reviewTask,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
  onBlurTextArea,
  setGoalsPlanned,
  goalsPlanned,
  userReview,
  isPreview,
}: IQuestionView) => {
  const getMultiLangString = useMultiLangString();
  const isExternalPeer = reviewTask?.userTo?.email;

  const isShowPreviousScore = userReview?.settings?.isShowPreviousScore;

  const prepareQuestion = (
    questionType: REVIEW_QUESTION_TYPES | REVIEW_QUESTION_TYPES_V1,
    questionData: IQuestionData,
  ) => {
    switch (questionType) {
      case REVIEW_QUESTION_TYPES.TEXT: {
        const textQuestionData = questionData as IQuestionDefaultData;
        const previousRating =
          userReview?.previousRatings && userReview?.previousRatings[textQuestionData.question.id];
        const relevantRating = ratings.find(
          (rating) => rating.question === textQuestionData.question.id,
        );

        return (
          <TextAreaQuestion
            key={textQuestionData.question.id}
            onChange={onChange}
            onBlur={onBlurTextArea}
            question={textQuestionData as IQuestionDefaultData}
            languageState={languageState}
            // @ts-ignore
            questionData={textQuestionData.question}
            canAnswer={textQuestionData.canAnswer}
            defaultValues={{
              answer: (relevantRating?.answer as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            showOtherRatings={showOtherRatings}
            isShowPreviousScore={isShowPreviousScore}
            previousRating={previousRating}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.RATING: {
        const ratingQuestionData = questionData as IQuestionDefaultData;
        const previousRating =
          userReview?.previousRatings &&
          userReview?.previousRatings[ratingQuestionData.question.id];
        const relevantRating = ratings.find(
          (rating) => rating.question === ratingQuestionData.question.id,
        );
        return (
          <RatingQuestion
            key={ratingQuestionData.question.id}
            onChange={onChange}
            question={ratingQuestionData as IQuestionDefaultData}
            canAnswer={ratingQuestionData.canAnswer}
            languageState={languageState}
            // @ts-ignore
            questionData={ratingQuestionData.question}
            defaultValues={{
              answer: relevantRating?.answer?.toString() ?? '',
              comment: (relevantRating?.comment as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            useMultiLangString={useMultiLangString}
            isShowPreviousScore={isShowPreviousScore}
            previousRating={previousRating}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        const skillQuestionData = questionData as IQuestionCustomSkillData;
        const relevantRatings = ratings.filter((rating) =>
          skillQuestionData.subQuestions.map((sq) => sq.question.id).includes(rating.question),
        );

        return (
          <SkillQuestion
            useMultiLangString={useMultiLangString}
            key={skillQuestionData.questionNumber}
            // @ts-ignore
            onChange={onChange}
            languageState={languageState}
            canAnswer={skillQuestionData.canAnswer}
            focusAreaQuestions={skillQuestionData.subQuestions}
            defaultValues={{
              answers: relevantRatings.map((rating) => ({
                value: (rating.answer as number) ?? undefined,
              })),
              isNotApplicable: relevantRatings[0]?.isNotApplicable ?? false,
              comment: relevantRatings[0]?.comment ?? '',
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            previousRatings={userReview?.previousRatings}
            isShowPreviousScore={isShowPreviousScore}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        const planGoalQuestionData = questionData as IQuestionGoalPlanData;
        return (
          !isExternalPeer && (
            <PlanGoalQuestion
              key={planGoalQuestionData.question.id}
              userFrom={reviewTask?.userFrom}
              subTypes={planGoalQuestionData.question.settings.subTypes}
              setGoalsPlanned={setGoalsPlanned}
              goalsPlanned={goalsPlanned}
              userReview={userReview}
              isPreview={isPreview}
              isGiveReview
            />
          )
        );
      }
      case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1:
      case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1: {
        const oldSkillQuestionData = questionData as IQuestionCustomSkillV1Data;
        const relevantRating = ratings.find((rating) =>
          oldSkillQuestionData.subQuestions.map((sq) => sq.question.id).includes(rating.question),
        );

        return (
          <OldSkillQuestion
            key={oldSkillQuestionData.questionNumber}
            onChange={onChange}
            question={oldSkillQuestionData as IQuestionCustomSkillV1Data}
            canAnswer={oldSkillQuestionData.canAnswer}
            languageState={languageState}
            defaultValues={{
              oldAnswer: relevantRating?.oldAnswer?.toString(),
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
              comment: relevantRating?.comment ?? '',
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            useMultiLangString={useMultiLangString}
          />
        );
      }
      default:
        return null;
    }
  };

  const description = useMemo(() => {
    return getMultiLangString(questionData?.questionDescription || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(questionData?.questionDescription)]);

  return (
    <Container className={className}>
      <HeaderWrapper>
        <ThemeTitle>
          {questionData.themeIconColor && questionData.themeIcon && (
            <ThemeContainer
              // @ts-ignore
              color={
                questionData.themeIconColor
                  ? (questionData.themeIconColor?.split('-') as string[])
                  : ['#f7f9ff', '#ebf1fe']
              }
            >
              <IconOld
                className="icon"
                name={questionData.themeIcon}
                color={COLORS.MIDDLE_GRAY}
                width={14}
                height={14}
              />
            </ThemeContainer>
          )}
          <span className="title">{`${questionData.themeName} | Question: ${questionData.questionNumber}`}</span>
        </ThemeTitle>
        <Title>{questionData.questionTitle}</Title>
        {!isEmpty(description) && (
          <Description>
            {/* added questionNumber to key prop to recognize the children and calculate height */}
            {/* @ts-ignore */}
            <ShowMore showMoreClassName="showMore" maxHeight={50} key={questionData.questionNumber}>
              <RickTextView html={description} />
            </ShowMore>
          </Description>
        )}
      </HeaderWrapper>
      <AnswerView
        overflow={questionData.type === REVIEW_QUESTION_TYPES.GOAL_PLAN ? 'unset' : 'auto'}
      >
        {prepareQuestion(questionData.type, questionData)}
      </AnswerView>
    </Container>
  );
};

export { QuestionView };
