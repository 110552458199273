import React, { useEffect } from 'react';

import { REVIEW_STATUS, REVIEW_VERSION } from '@learned/constants';
import { IReviewTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { UseFormReturn } from 'react-hook-form';

import Switch from '~/components/Switch';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { areWeightedThemesValid } from '~/pages/Reviews/utils';

import { isOldReview } from '~/utils/reviewUtils';

import {
  Separator,
  SubSectionWrapper,
  SubTitle,
  ToggleContainer,
  ToggleRow,
  ToggleText,
} from '../design';

import type { GeneralSettingsType, IReviewSelfForm } from '../types';
import type { I18n } from '@lingui/core';

interface GeneralSettingsProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  reviewTemplates: IReviewTemplate[];
  version?: REVIEW_VERSION;
}

type ToggleItem = {
  key: GeneralSettingsType;
  text: (i18n: I18n) => string;
  tooltip: (i18n: I18n) => string;
};

const TOGGLE_LIST: Array<ToggleItem> = [
  {
    key: 'isDigitalSign',
    text: (i18n: I18n) => i18n._(t`Digitally sign the review reports`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Participants need to sign the review and receive a reminder 7 days before the end date`,
      ),
  },
  {
    key: 'isShowOverallRating',
    text: (i18n: I18n) => i18n._(t`Show the overall rating of the employee in the review report`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`Besides ratings per question, the review report will also contain an additional section with average ratings per theme and an overall rating`,
      ),
  },
  {
    key: 'isShowPreviousScore',
    text: (i18n: I18n) => i18n._(t`Show the previous scores`),
    tooltip: (i18n: I18n) =>
      i18n._(
        t`When answering the review questions and viewing the review report the previous answers to this question are shown.`,
      ),
  },
];

const GeneralSettings = ({ formMethods, reviewTemplates, version }: GeneralSettingsProps) => {
  const { watch, setValue } = formMethods;
  const { i18n } = useLingui();

  const watchReviewState = watch('status');
  const isDraft = watchReviewState === REVIEW_STATUS.DRAFT;

  const onChangeToggle = (key: GeneralSettingsType) => {
    setValue(`settings.${key}`, !watch(`settings.${key}`), { shouldDirty: true });
  };

  const savedStartDate = new Date(watch('fetchedCycle').settings.startDate);
  const today = new Date();
  const watchCalibrate = watch('settings.isCalibrate');
  const watchDigitalSign = watch('settings.isDigitalSign');
  const watchReviewTemplate = watch('reviewTemplate');

  // TODO: it seems like we need to check review active status here, backend throws an error otherwise,
  const isDigitalSignDisabled = !isDraft && savedStartDate && savedStartDate < today;
  const isCalibrateDisabled =
    !isDraft && savedStartDate && savedStartDate <= today && watchCalibrate;

  const isShowPreviousScoreDisabled = () => {
    if (!watchDigitalSign) {
      return [REVIEW_STATUS.ARCHIVED].includes(watchReviewState);
    } else {
      // not possible to navigate to the edit view while in the archived or signing mode. so handling that case seemed not necessary
      return [REVIEW_STATUS.ARCHIVED, REVIEW_STATUS.COMPLETED].includes(watchReviewState);
    }
  };

  const isPrevScoreDisabled = isShowPreviousScoreDisabled();

  const isDisabled = (key: GeneralSettingsType) => {
    if (key === 'isCalibrate') {
      return isCalibrateDisabled;
    }
    if (key === 'isDigitalSign') {
      return isDigitalSignDisabled;
    }
    return false;
  };

  const selectedTemplate = reviewTemplates.find((template) => template.id === watchReviewTemplate);

  const isWeightedThemesValid = areWeightedThemesValid(selectedTemplate);

  interface TooltipCondition {
    condition?: boolean;
    message: string;
  }

  const getTooltipMessage = (item: ToggleItem, i18n: I18n): string => {
    const conditions: TooltipCondition[] = [
      {
        condition: item.key === 'isShowOverallRating' && isOldReview(version),
        message: i18n._(t`Can only be enabled when reviews use templates with weights`),
      },
      {
        condition: item.key === 'isShowOverallRating' && !isWeightedThemesValid,
        message: i18n._(t`The selected template does not contain weighted themes`),
      },
      {
        condition: item.key === 'isShowPreviousScore' && isOldReview(version),
        message: i18n._(t`This functionality cannot be enabled for old reviews`),
      },
      // when the review version is 3.0, but the isShowPreviousScore is disabled for other reasons,
      {
        condition:
          item.key === 'isShowPreviousScore' && !isOldReview(version) && isPrevScoreDisabled,
        message: i18n._(t`It is no longer possible to change this setting`),
      },
    ];

    // Find the first matching condition
    const matchedCondition = conditions.find(({ condition }) => condition);
    return matchedCondition ? matchedCondition.message : item.tooltip(i18n);
  };

  const getCheckedValue = (
    item: ToggleItem,
    watch: UseFormReturn<IReviewSelfForm>['watch'],
  ): boolean => {
    if (item.key === 'isShowOverallRating' && !isWeightedThemesValid) {
      return false;
    }
    return watch(`settings.${item.key}`);
  };

  useEffect(() => {
    if (!isWeightedThemesValid) {
      setValue('settings.isShowOverallRating', false);
    }
  }, [isWeightedThemesValid, setValue]);

  return (
    <SubSectionWrapper>
      <SubTitle>
        <Trans>General</Trans>
      </SubTitle>
      <Separator />
      <ToggleContainer>
        {TOGGLE_LIST.map((item) => (
          <ToggleRow key={item.key} marginTop="-10px" marginBottom="-14px">
            <ToolTip
              tooltip={i18n._(
                t`It is not possible to change this setting after the review has started`,
              )}
              disabled={!isDisabled(item.key) || isPrevScoreDisabled}
            >
              <span>
                <Switch
                  disabled={
                    isDisabled(item.key) ||
                    (item.key === 'isShowOverallRating' &&
                      (isOldReview(version) || !isWeightedThemesValid)) ||
                    (item.key === 'isShowPreviousScore' &&
                      (isOldReview(version) || isPrevScoreDisabled))
                  }
                  onChange={() => onChangeToggle(item.key)}
                  checked={getCheckedValue(item, watch)}
                />
              </span>
            </ToolTip>
            <ToolTip tooltip={getTooltipMessage(item, i18n)} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
              <ToggleText>{item.text(i18n)}</ToggleText>
            </ToolTip>
          </ToggleRow>
        ))}
      </ToggleContainer>
    </SubSectionWrapper>
  );
};

export { GeneralSettings };
